<template>
  <router-view></router-view>
</template>

<script>
export default {
  async created() {
    const isAuthenticated = await this.$auth.isAuthenticated();
    const tenantFromRoute = this.$route.params.tenant;
    if (tenantFromRoute && !isAuthenticated) {
      this.dataService = this.$dataService(tenantFromRoute);
      this.$router.push({ name: 'landing', params: { tenant: tenantFromRoute } });
    } else if (tenantFromRoute && isAuthenticated) {
      if (this.$route.meta.title === 'Home') {
        this.$router.push({ name: 'landing', params: { tenant: tenantFromRoute } });
      }
    }
  },
};
</script>
